import React, { useState } from "react";
import { Container, Navbar } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import "../NavMenu.css";
import { Link } from "react-router-dom";

export const NavMenu = (props: {userName: string, id?: string}) => {
  const [show, setShow] = useState(false)
  const {userName, id} = props
  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        light
      >
        <div className="container-fluid ms-3 me-3">
        <div className="row">
            <div className="col-4">
              <div className="d-flex h-100">
              <Link
              to={id ? `/${id}` : "/"}
              className="align-self-center cursor"
              style={{width:100}}
              >
                <img src={process.env.PUBLIC_URL + "/img/logo.png"} width={"130px"} height={"36px"} alt="Vapors"/>
              </Link>
              </div>
              
            </div>
            {/* <div className="col-2">
              <div className="d-flex h-100">
              <Link
              to={`/discounts`}
              className="align-self-center cursor text-end"
              style={{width:60}}
              >
                <span className="bold text-dark fst-italic text-decoration-underline cursor"> Акції</span>
              </Link>
              </div>
              
            </div> */}
            {/* <h4 className="bold">Telegram </h4>
          <h5 className="fw-lighter mt-0.5"><a href="https://telegram.me/vapors_wholesale" className="text-dark">@vapors_wholesale</a></h5>
          <h4 className="bold mt-3">Контакнтий номер</h4>
          <h5 className="fw-lighter mt-0.5"><a href="tel:+380937683929" className="text-dark">+380 93 768 39 29</a></h5> */}
            {/* //<div className="col-6 text-end"><div className="div">Ви: {userName}</div><div className="bold cursor" onClick={() => setShow(true)}>Контакт менеджера</div></div> */}
            <div className="col-8 text-end"><div className="div">Ви: {userName}</div><div className="bold">Контакт менеджера</div><div><a href="tel:+380997114561" className="text-dark me-2">+380&nbsp;99&nbsp;711&nbsp;45&nbsp;61</a><a href="https://telegram.me/vapors_wholesale" className="text-dark">@vapors_wholesale</a></div></div>

          </div>
        </div>
      </Navbar>
      {/* <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="d-flex flex-column text-center m-3">
          <h4 className="bold">Telegram </h4>
          <h5 className="fw-lighter mt-0.5"><a href="https://telegram.me/vapors_wholesale" className="text-dark">@vapors_wholesale</a></h5>
          <h4 className="bold mt-3">Контакнтий номер</h4>
          <h5 className="fw-lighter mt-0.5"><a href="tel:+380937683929" className="text-dark">+380 93 768 39 29</a></h5>
          </div>
        </Modal.Body>
      </Modal> */}
      
    </header>
  );
}

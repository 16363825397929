import React, { useEffect, useState, FC } from "react";
import Modal from "react-bootstrap/Modal";
import { IPropsRow } from "../interfaces/interfaces";

export const RowDesktopComp: FC<IPropsRow> = (props) => {

  const {setPrice, price, name, id, orderPrice, imageUrl, category, quantity, display, userPriceMRPUAH, coefficient, currency, portrait, auth, discounts, discountsSum, selectedDiscount} = props

  const currencySymbol = currency === "USD" ? "$": "₴" 
  
  const elf = "Elf Bar RF350";
  const [amount, setAmount] = useState(0);
  const [activeDiscount, setActiveDiscount] = useState(0);
  const [priceWithOutDiscount, setPriceWithOutDiscount] = useState(0);
  const [show, setShow] = useState(false);
  // const [show, setShow] = useState(false);

  useEffect(() => {
    if (discounts && discounts.length > 0) {
      let maxDiscount = 0;
      discounts.forEach((el) => {
        if (amount >= el.threshold && el.discount > maxDiscount) {
          maxDiscount = el.discount;
        }
      });
      if (maxDiscount > 0) {
        setPrice(price * amount - (price * amount * maxDiscount / 100), id, amount);
        setPriceWithOutDiscount(price * amount);
        setActiveDiscount(maxDiscount);
      } else {
        setPrice(price * amount, id, amount);
        setPriceWithOutDiscount(0);
        setActiveDiscount(0);
      }
    } else {
      setPrice(price * amount, id, amount);
      setPriceWithOutDiscount(0);
      setActiveDiscount(0);
    }
    
  }, [amount]);

  const decrease = () => {
    if (amount - coefficient >= 0) setAmount((prev) => +prev - coefficient)
    // if (quantity >= amount && amount > 0) setAmount((prev) => +prev - 1);
  };

  const increase = () => {
    //setAmount((prev) => +prev + 1);
    //if (quantity > amount) setAmount((prev) => +prev + 1);
    if (quantity >= amount + coefficient) {
      setAmount((prev) => +prev + coefficient);
    }
  };


  const priceForOneItemWithDiscount = (price: number, discount: number) => price - (price * discount / 100)

  const validData = (price: number) => (price / 100).toFixed(2).replace(".", ",");

  const inputChange = (e : React.ChangeEvent<HTMLInputElement>) => {
      //quantity <= 50 ? +(e.target.value) <= quantity && +e.target.value >= 0 && setAmount(+e.target.value) : +e.target.value >= 0 && setAmount(+e.target.value)
      // +e.target.value >= 0 && setAmount(+e.target.value)
      // setAmount(+e.target.value)
      if (quantity >= +e.target.value) {
        setAmount(+e.target.value)
      }
  }

  const blurChange = (e: React.FocusEvent<HTMLInputElement>) => {
    // e.target.value === "" && setAmount(0)
    e.target.value === "" || +e.target.value <= 0 ? setAmount(0) : +e.target.value % coefficient !== 0 && setAmount(Math.floor(+e.target.value/coefficient)*coefficient)
  }

  return (
    <tr style={{ display: display ? "table-row" : "none" }}>
      {/* <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <img
            className="pic-high img-thumbnail img-fluid"
            src={"data:image/png;base64, " + imageUrl}
            alt="error"
          ></img>
        </Modal.Body>
      </Modal> */}
       <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="mod-details">
            <div>Додаткова примітка:</div>
            <div>{portrait}</div>
         </div>
        </Modal.Body>
      </Modal>
      <th scope="row" className="">
        <div className="d-flex justify-content-center">
          <div className="cont-img desc">
            {imageUrl !== "null" ? (
              <img
                // onClick={() => setShow(true)}
                className="pic img-thumbnail img-fluid"
                src={"data:image/png;base64, " + imageUrl}
                alt="error"
              ></img>
            ) : (
              <img
                className="pic img-thumbnail img-fluid"
                alt="error"
                src="img/image-logo.png"
              ></img>
            )}
          </div>
        </div>
      </th>
      <td>
        <div className="description-cont text-center">
          <div className="description">
            <span>{name}</span>
            {/* <span className="bold"> <br/>{portrait}</span> */}
            {portrait ? (
                      <div className="details">
                        <span onClick={() => setShow(true)}>Сумісно з...</span>
                      </div>
                    ) : (
                      <></>
                    )}
          </div>
        </div>
      </td>
      {auth ? <></> : (
              <><td>
              <div className= 'price text-center d-flex flex-column'>
              <span className={`fs-6 ${activeDiscount === 0 && amount > 0 && discounts && 'text-danger' }`} > від 1 шт = {validData(price)}&nbsp;{currencySymbol}</span>
                {discounts && discounts.length > 0 ? 
                <div className="mb-3  text-center d-flex flex-column">
                  {discounts.map((el, index) => 
                    <span key={index} className={`fs-6 text-nowrap ${activeDiscount === el.discount && 'text-danger' }`}> від {el.threshold} шт = {validData(priceForOneItemWithDiscount(price, el.discount))} {currencySymbol}</span>)}
                  </div> : <></>}
              </div>
            </td>
           
            <td>
              <div className="price text-center d-flex flex-column">
              {discountsSum?.map((el, index) => 
              <span key={index} className={`fs-6 text-nowrap ${selectedDiscount === el.discount && 'text-danger'}`}>від {validData(el.thresholdSum)}{currencySymbol} = {el.discount}% </span>)}
                </div>
            </td>
            </>
            )}
      {/* <td>
        <div className="price text-center">
          <span>{validData(price)} {currencySymbol}/шт</span>
        </div>
      </td>
      <td>
        <div className="price text-center">
          <span>{validData(userPriceMRPUAH)} ₴</span>
        </div>
      </td> */}
    {/* {
      discountsSum && discountsSum.length > 0 ? (
        <td>
        <div className="price text-center">
          <div className="d-flex flex-column">
            {discountsSum?.map((el, index) => 
              <span key={index} className={`text-nowrap`}>від {validData(el.thresholdSum)}{currencySymbol} = {el.discount}% </span>)}
            </div>
        </div>
      </td>
      ) :  <></> 
    } */}
      <td>
        <div className="price text-center">
          {quantity < 100 ? (
            category === elf ? (
              <span>{quantity} пачок</span>
            ) : (
              <span>{quantity} шт</span>
            )
          ) : category === elf ? (
            <span>100+ пачок</span>
          ) : (
            <span>Більше 100+</span>
          )}
        </div>
      </td>
      {auth ? <></> : (
        <><td>
        <div className="d-flex justify-content-center">
          <form className="d-flex float-start">
            <span className="input-group-prepend">
              <button
                type="button"
                className="btn btn-outline-danger m-1 round-pl"
                data-type="minus"
                data-field="quant[1]"
                onClick={decrease}
              >
                <span className="fa fa-minus">-</span>
              </button>
            </span>
            <input
              type="number"
              className="w-100 text-center m-1 form-control"
              max={quantity}
              step={coefficient}
              value={amount}
              onChange={inputChange}
              onBlur={blurChange}
            />
            <span className="input-group-append">
              <button
                type="button"
                className="btn btn-outline-primary m-1 float-left round-pl"
                data-type="plus"
                data-field="quant[1]"
                onClick={increase}
              >
                <span className="fa fa-plus">+</span>
              </button>
            </span>
          </form>
        </div>
      </td>
      <td>
        <div className="price text-center d-grid">
          {/* <span>Ціна</span> */}
          {/* <span>{validData(orderPrice)}&nbsp;{currencySymbol}</span> */}
          {priceWithOutDiscount > 0 ? 
          <>
            <span className="small">Ціна</span>
            <span>{validData(priceWithOutDiscount)}&nbsp;{currencySymbol}</span>
            <span className="text-danger small">Ціна зі знижкою</span>
            <span className="text-danger">{validData(orderPrice)}&nbsp;{currencySymbol}</span>
          </> :  
          <span>{validData(orderPrice)}&nbsp;{currencySymbol}</span>}
        </div>
      </td></>
      )}
      {/* <td>
        <div className="d-flex justify-content-center">
          <form className="d-flex float-start">
            <span className="input-group-prepend">
              <button
                type="button"
                className="btn btn-outline-danger m-1 round-pl"
                data-type="minus"
                data-field="quant[1]"
                onClick={decrease}
              >
                <span className="fa fa-minus">-</span>
              </button>
            </span>
            <input
              type="number"
              className="w-100 text-center m-1 form-control"
              max={quantity}
              step={coefficient}
              value={amount}
              onChange={inputChange}
              onBlur={blurChange}
            />
            <span className="input-group-append">
              <button
                type="button"
                className="btn btn-outline-primary m-1 float-left round-pl"
                data-type="plus"
                data-field="quant[1]"
                onClick={increase}
              >
                <span className="fa fa-plus">+</span>
              </button>
            </span>
          </form>
        </div>
      </td>
      <td>
        <div className="price text-center">
          <span>{validData(orderPrice)}&nbsp;{currencySymbol}</span>
        </div>
      </td> */}
    </tr>
  );
};


import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RowDesktopComp } from "./RowDesktopComp";
import { RowComp } from "./RowComp";
import { NavMenu } from "./NavMenu";
import "../main.css";
import { IDataItem, IDiscountDescription, IFullPrice, IResponse } from "../interfaces/interfaces";
import ConclusionMenu from "./ConclusionMenu";
import axios from "axios";
import { group } from "console";
// const json = require("./data.json")

const ItemComp = () => {
  const [data, setData] = useState<IDataItem[]>([]);
  const [fullprice, setFullprice] = useState<IFullPrice>({
    dollar: 0,
    grivnya: 0
  });
  const [fullAmount, setFullAmount] = useState<IFullPrice>(
    {
      dollar: 0,
      grivnya: 0
    }
  );
  const [auth, setAuth] = useState(false)
  const [fullVisible, setFullVisible] = useState<boolean>(false);
  const [userName, setUsername] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const [displayItems, setDisplayItems] = useState(false)
  const [action, setAction] = useState<boolean>(false);
  const navigate = useNavigate();
  const [discountDisplay, setDiscountDisplay] = useState(false);
  const [discounts, setDiscounts] = useState<IDiscountDescription[]>([]);
  const [brandDiscounts, setBrandDiscounts] = useState<Map<string, string>>(new Map());
  const [selectedDiscount, setSelectedDiscount] = useState<Map<string, number>>(new Map());

  const FetchData = () => {

  axios.get(`https://vaporstlgrm.biz.ua/VPR_UNF/hs/siteListener/getPrice/${id}`)
   .then(({data: {row, username}}) => {
          row.forEach(
            ({ name, userPrice, id, category, imageUrl, quantity, userPriceMRPUAH, coefficient, currency, portrait, discounts, actiongroup, group, discountsSum} :IResponse) => {
              const newObj = {
                name,
                price: userPrice,
                orderPrice: 0,
                amount: 0,
                id,
                category: {name: category.trim(), display: false},
                imageUrl,
                display: false,
                quantity,
                userPriceMRPUAH,
                coefficient,
                currency,
                portrait,
                discounts,
                actiongroup,
                group,
                discountsSum
              };
              setUsername(username);
              setData(prev => [...prev, newObj]);
            }
          );
          setLoading(true);
        })
        .catch(() => {
          localStorage.setItem("userName", "Гість");
            navigate("/response", {
           state: {
            title: "Exception!",
              desc: ", вибачайте,  трапилась помилка",
              userName: "Гість",
           } 
          })       
        });

        axios.get("https://opt.vaporstlgrm.biz.ua:8800/api/promo")
        .then((res) => {
          res.data.forEach(
                  ({ id, title, description, titleFontColor, titleFontWeight, descriptionFontColor, descriptionFontWeight, titleFontSize, descriptionFontSize,  isEnabled, titleFontStyle, descriptionFontStyle} :IDiscountDescription) => {
                    const newObj = {
                      id,
                      title,
                      description,
                      titleFontColor,
                      titleFontWeight,
                      descriptionFontColor,
                      titleFontSize,
                      descriptionFontSize, 
                      descriptionFontWeight,
                      descriptionFontStyle,
                      titleFontStyle
                      };
                    if(isEnabled){
                      setDiscounts(prev => [...prev, newObj]);
                  }
                  }
                );
              })
              .catch(() => {
                  navigate("/response", {
                   state: {
                    title: "Exception!",
                      desc: ", вибачайте,  трапилась помилка",
                      userName: "Гість",
                   } 
                  })       
                });
              };

  useEffect(() => {
    FetchData();
  }, []);

  useEffect(() => {
    userName === "Не авторизовано" && setAuth(true);
  }, [userName]);

  useEffect(() => {
    setFullVisible(!!(fullAmount.dollar || fullAmount.grivnya));
  }, [fullAmount]);

  useEffect(() => {
    const displayData = data.find(({ display }) => display);
    displayData ? setDisplayItems(true) : setDisplayItems(false);
  
    const brandMap = new Map<string, number>();
    
  
    data.forEach(item => {
      if (item.amount > 0 && item.discountsSum) {
        const { group, price } = item;
        const currentTotalPrice = (brandMap.get(group) || 0) + item.amount * price;
        brandMap.set(group, currentTotalPrice);
      }
    });
  
    const newBrandDiscounts = new Map<string, string>();
    const newSelectedDiscount = new Map<string, number>();
  
    brandMap.forEach((totalPrice, group) => {
      let totalDiscountValue = 0;
      let currency = "";
      let selectedDiscount = 0;
  
      data.forEach(item => {
        if (item.group === group && item.discountsSum) {
          item.discountsSum.forEach(({ thresholdSum, discount }) => {
            if (totalPrice >= thresholdSum) {
              currency = item.currency;
              selectedDiscount = discount;
              totalDiscountValue = (totalPrice * discount) / 100;
            }
          });
        }
      });
  
      if (totalDiscountValue > 0) {
        newBrandDiscounts.set(group, `${currency}/${totalDiscountValue}`);
        newSelectedDiscount.set(group, selectedDiscount);
      }
    });
  
    setBrandDiscounts(newBrandDiscounts);
    setSelectedDiscount(newSelectedDiscount);
    // data.forEach(({ amount }) => {
    //   if (amount >= 0) {
    //     setFull();
    //   }
    // });
  
  }, [data]);
  

  const setPrice = (newPrice: number, id: string, amount: number) => {
    const mappedData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          orderPrice: newPrice,
          amount,
        };
      }
      return item;
    });
    setData(mappedData);
  };

   useEffect(() => {
    const newData = data.reduce((accumulator, { orderPrice, amount, currency }) => {
      if (currency === 'USD') {
        accumulator.dollar += orderPrice;
        accumulator.dollarAmount += amount;
      } else {
        accumulator.grivnya += orderPrice;
        accumulator.grivnyaAmount += amount;
      }
      return accumulator;
    }, { dollar: 0, grivnya: 0, dollarAmount: 0, grivnyaAmount: 0 });

    brandDiscounts.forEach((value) => {
      const [currency, discount] = value.split('/');
      if (currency === 'USD') {
        newData.dollar -= parseFloat(discount);
      } else {
        newData.grivnya -= parseFloat(discount);
      }
    })

    setFullprice({
      dollar: newData.dollar,
      grivnya: newData.grivnya
    });

    setFullAmount({
      dollar: newData.dollarAmount,
      grivnya: newData.grivnyaAmount
    });
  }, [data, brandDiscounts]);

  const validData = (price: number) => (price / 100).toFixed(2).replace(".", ",");

  const changeStateOfDisplay = (category: string): void => {
    setData(prevItems => prevItems.map(item => {
      if (item.category.name === category) {
        item.category.display = !item.category.display;
        return { ...item, display: !item.display };
      }
      return item;
    }));
  };
  return loading ? (
    window.innerWidth > 576 ? (
      <div>
        <NavMenu userName={userName} id={id}/>
        <ConclusionMenu
          auth = {auth} 
          fullVisible = {fullVisible} 
          fullprice = {fullprice}
          validData = {validData}
          fullAmount = {fullAmount}
          data = {data}
          userName = {userName} 
          id = {id}
          />
        <div className="container-fluid">

        {discounts.length > 0 && 
                <div className={"no-border"}>
                        <h3 className="text-center">Список наявних акцій</h3>                  
                        <div className="spec d-flex w-100" style={{height: "5rem"}}>
                        <span className="position-absolute" onClick={() => setDiscountDisplay(!discountDisplay)}>
                          <div className="d-inline-flex align-items-center">Акції {discountDisplay ? <i className="gg-compress-left mg-12"></i> : <i className="gg-arrows-expand-left mg-5"></i>}</div>
                        </span>
                        </div>
                </div>}
                {discountDisplay && discounts &&
                <div className="mb-5">
                  {discounts.map(({id, title, description, titleFontColor, titleFontWeight, descriptionFontColor, titleFontSize, descriptionFontWeight, descriptionFontSize,  isEnabled, titleFontStyle, descriptionFontStyle}: IDiscountDescription, index) => {
                    return (
                        <div key={index} className="mt-3 w-50">
                            <div className="card">
                                <div className="card-body">
                                <h5 className="card-title" style={{color: titleFontColor, fontWeight: titleFontWeight, fontSize: titleFontSize, fontStyle: titleFontStyle ? "italic" : 'normal'}}>{title}</h5>
                                <p className="card-text" style={{color: descriptionFontColor, fontWeight: descriptionFontWeight, fontSize: descriptionFontSize, fontStyle: descriptionFontStyle ? "italic" : 'normal'}}>{description}</p>
                                </div>
                            </div>
                        </div>
                    );
                  })}
                </div>}

          <table className="table table-bordered align-middle">
            <thead className="thead-dark">
              <tr className={displayItems || action  ? "text-center" : "text-center no-border"}>
                {displayItems || action ? 
                 auth ?  
                  <>
                    <th scope="col">Фото</th>
                    <th scope="col">Назва</th>
                    {/* <th scope="col">Ціна</th>
                    <th scope="col">МРЦ</th> */}
                    <th scope="col">Наявність</th>
                    {/* <th scope="col">Кількість</th>
                    <th scope="col">Сума</th> */}
                  </>   : 
                  <>
                    <th scope="col">Фото</th>
                    <th scope="col">Назва</th>
                    <th scope="col">Ціна</th>
                    <th scope="col">Знижка по бренду</th>
                    <th scope="col">Наявність</th>
                    <th scope="col">Кількість</th>
                    <th scope="col">Сума</th>
                  </> 
            
                : <h3>Виберіть необхідні категорії</h3>
                }
              </tr>
            </thead>
            <tbody>
            <React.Fragment>
                      <tr className={"no-border"}>
                        <div className="spec d-flex w-100" style={{height: "5rem"}}>
                          <span className="position-absolute" onClick={() => setAction(!action)}>
                          <div className="d-inline-flex align-items-center">Акційні товари {action ? <i className="gg-compress-left mg-12"></i> : <i className="gg-arrows-expand-left mg-5"></i>}</div>
                          </span>
                        </div>
                      </tr>
            </React.Fragment>
            {data.map(
                (
                  { id, name, orderPrice, price, category, imageUrl, quantity, userPriceMRPUAH, coefficient, currency, portrait, discounts, actiongroup, group},
                ) => {
                  if(actiongroup) {
                  return (
                      <RowDesktopComp
                        category={category.name}
                        quantity={quantity}
                        name={name}
                        imageUrl={imageUrl}
                        orderPrice={orderPrice}
                        id={id}
                        price={price}
                        display = {action}
                        setPrice={setPrice}
                        userPriceMRPUAH={userPriceMRPUAH}
                        coefficient={coefficient}
                        currency={currency}
                        portrait={portrait}
                        auth = {auth}
                        discounts = {discounts}
                        selectedDiscount = {Array.from(selectedDiscount.entries()).find(([key, value]) => key === group)?.[1] ?? null
                        }
                      />
                  );
                }
              })}
             {data.map(
  (
    { id, name, orderPrice, price, category, imageUrl, quantity, display, userPriceMRPUAH, coefficient, currency, portrait, discounts, discountsSum, group },
    count,
    arr
  ) => {
    const prev = arr[count - 1];

    const hasBrandDiscount = arr.some(item => item.category.name === category.name && item.discountsSum && item.discountsSum?.length > 0);

    return (count > 0 && category.name !== prev.category.name) || count === 0 ? (
      <React.Fragment key={id}>
        <tr className={displayItems ? "no-border" : "no-border"}>
          <div className="spec d-flex w-100" style={{ height: "5rem" }}>
            <span className="position-absolute d-flex w-75" onClick={() => changeStateOfDisplay(category.name)}>
              <div className="d-inline-flex align-items-center w-100">
                {category.name} {category.display ? <i className="gg-compress-left mg-12"></i> : <i className="gg-arrows-expand-left mg-5"></i>}
              </div>
              
              {category.display ? <div className="text-black float-right fs-6 text-end w-100 bold">Знижки по кількості та знижка по бренду сумується</div>: <></>}
              
            </span>
          </div>
        </tr>
        <RowDesktopComp
          category={category.name}
          quantity={quantity}
          name={name}
          imageUrl={imageUrl}
          orderPrice={orderPrice}
          id={id}
          price={price}
          display={display}
          setPrice={setPrice}
          userPriceMRPUAH={userPriceMRPUAH}
          coefficient={coefficient}
          currency={currency}
          portrait={portrait}
          auth={auth}
          discounts={discounts}
          discountsSum={discountsSum}
          selectedDiscount={Array.from(selectedDiscount.entries()).find(([key, value]) => key === group)?.[1] ?? null}
        />
      </React.Fragment>
    ) : (
      <RowDesktopComp
        key={id}
        category={category.name}
        quantity={quantity}
        name={name}
        imageUrl={imageUrl}
        orderPrice={orderPrice}
        id={id}
        price={price}
        display={display}
        setPrice={setPrice}
        userPriceMRPUAH={userPriceMRPUAH}
        coefficient={coefficient}
        currency={currency}
        portrait={portrait}
        auth={auth}
        discounts={discounts}
        discountsSum={discountsSum}
        selectedDiscount={Array.from(selectedDiscount.entries()).find(([key, value]) => key === group)?.[1] ?? null}
      />
    );
  }
)}

              <tr
                className="space mt-3"
                style={{ display: fullVisible || auth ? "flex" : "none" }}
              ></tr>
            </tbody>
          </table>
        </div>
      </div>
    ) : (
      <div>
        <NavMenu userName={userName} id={id}/>
        <div className="container">
        <ConclusionMenu 
          auth = {auth} 
          fullVisible = {fullVisible} 
          fullprice = {fullprice}
          validData = {validData}
          fullAmount = {fullAmount}
          data = {data}
          userName = {userName} 
          id = {id}
          />
          {displayItems? <div className=""></div>: <h2 className="text-center mb-5">Виберіть необхідні категорії</h2>}

              <React.Fragment>
              <div className="row mb-5">
                <div className="col-12">
                  <div className="spec">
                  <span className="position-absolute" onClick={() => setAction(!action)}>
                      <div className="d-inline-flex align-items-center">Акційні товари {action  ? <i className="gg-compress-left mg-12"></i> : <i className="gg-arrows-expand-left mg-5"></i>}</div>
                      </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
            {data.map(
                (
                  { id, name, orderPrice, price, category, imageUrl, quantity, userPriceMRPUAH, coefficient, currency, portrait, discounts, actiongroup},
                ) => {
                  if(actiongroup) {
                  return (
                      <RowComp
                        category={category.name}
                        quantity={quantity}
                        name={name}
                        imageUrl={imageUrl}
                        orderPrice={orderPrice}
                        id={id}
                        price={price}
                        display = {action}
                        setPrice={setPrice}
                        userPriceMRPUAH={userPriceMRPUAH}
                        coefficient={coefficient}
                        currency={currency}
                        portrait={portrait}
                        auth = {auth}
                        discounts = {discounts}
                      />
                  );
                }
              })}
            {
              discounts.length > 0 && <div className="row mb-5">
                <div className="spec d-flex w-100">
                <span className="position-absolute" onClick={() => setDiscountDisplay(!discountDisplay)}>
                  <div className="d-inline-flex align-items-center">Акції {discountDisplay ? <i className="gg-compress-left mg-12"></i> : <i className="gg-arrows-expand-left mg-5"></i>}</div>
                </span>
                </div>
            </div>
            }
                {discountDisplay && discounts &&
                <div className="mt-5 pt-2">
                  {discounts.map(({id, title, description, titleFontColor, titleFontWeight, descriptionFontColor, titleFontSize, descriptionFontWeight, descriptionFontSize,  isEnabled, titleFontStyle, descriptionFontStyle}: IDiscountDescription, index) => {
                    return (
                        <div key={index} className="mt-5 no-border">
                          <div className="no-border w-5"></div>
                            <div className="no-border">
                            <div className="card">
                                <div className="card-body">
                                <h5 className="card-title" style={{color: titleFontColor, fontWeight: titleFontWeight, fontSize: titleFontSize, fontStyle: titleFontStyle ? "italic" : 'normal'}}>{title}</h5>
                                <p className="card-text" style={{color: descriptionFontColor, fontWeight: descriptionFontWeight, fontSize: descriptionFontSize, fontStyle: descriptionFontStyle ? "italic" : 'normal'}}>{description}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    );
                  })}
                </div>}

          {/* <h2 className={displayItems ? "d-none" : "text-center"}>Виберіть необхідні категорії</h2> */}
          {data.map(
            (
              { id, name, orderPrice, price, category, imageUrl, quantity, display, userPriceMRPUAH, coefficient, currency, portrait, discounts},
              count,
              arr
            ) => {
              const prev = arr[count - 1];
              return (count > 0 && category.name !== prev.category.name) ||
                count === 0 ? (
                <React.Fragment key={id}>
                  <div className="row mb-5">
                    <div className="col-12">
                      <div className="spec">
                      <span className="position-absolute" onClick={() => changeStateOfDisplay(category.name)}>
                          <div className="d-inline-flex align-items-center">{category.name} {category.display ? <i className="gg-compress-left mg-12"></i> : <i className="gg-arrows-expand-left mg-5"></i>}</div>
                          </span>
                      </div>
                    </div>
                  </div>
                  <RowComp
                    key={id}
                    name={name}
                    quantity={quantity}
                    category={category.name}
                    imageUrl={imageUrl}
                    orderPrice={orderPrice}
                    id={id}
                    price={price}
                    setPrice={setPrice}
                    display={display}
                    userPriceMRPUAH={userPriceMRPUAH}
                    coefficient={coefficient}
                    currency={currency}
                    portrait={portrait}
                    auth = {auth}
                    discounts = {discounts}
                  />
                </React.Fragment>
              ) : (
                <RowComp
                  key={id}
                  name={name}
                  quantity={quantity}
                  category={category.name}
                  imageUrl={imageUrl}
                  orderPrice={orderPrice}
                  id={id}
                  price={price}
                  setPrice={setPrice}
                  display={display}
                  userPriceMRPUAH={userPriceMRPUAH}
                  coefficient={coefficient}
                  currency={currency}
                  portrait={portrait}
                  auth = {auth}
                  discounts={discounts}
                  />
              );
            }
          )}
          <div
            className="space mb-3"
            style={{ display: fullVisible || auth ? "flex" : "none" }}
          ></div>
        </div>
      </div>
    )
  ) : (
    <div className="loader"></div>
  );
};

export default ItemComp
